import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <section className="gray intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{height: "50vh", display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '100px'}}>
              <blockquote>Work-in-progress</blockquote>
              <h1>This page is coming soon.</h1>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
